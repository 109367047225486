<template>
  <section class="portfolio">
    <div class="page-header">
      <!-- <h3 class="page-title" style="color:#696969 !important;">
        All Courses
      </h3> -->
      <!-- @click="showAddModal() -->
      <!-- <b-button @click="showAddModal()" class="btn btn-success btn-fw"><i class="fa fa-plus"></i> Add Category
      </b-button> -->
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="row portfolio-grid">

                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                    <figure class="effect-text-in" style="height: 300px; color: #28a745;">
                      <i class="mdi mdi-format-list-bulleted" style="font-size: 150px;"></i>
                      <!-- <img src="" alt="image" /> -->
                      <!-- <h2 style="color:#696969 !important; text-align: left; margin-left:20px;">Course Categories</h2> -->
                      <figcaption>
                        <div>
                          <div class="btn-group" role="group" aria-label="Basic example"
                            style="position: absolute; bottom: 10px; left: 0; padding: 10px; margin-bottom: 0; width: 100%;">
                            <RouterLink class="btn btn-success" to="/course-categories/list"><i class="mdi mdi-eye"></i>
                            </RouterLink>
                            <!-- <button type="button"
                              @click="showEditModal(item.id, item.name, item.description, item.type, item.image)"
                              class="btn btn-warning"><i class="mdi mdi-lead-pencil"></i></button>
                            <button v-on:click="showAlert(item.id)" type="button" class="btn btn-danger"><i
                                class="mdi mdi-delete-forever"></i></button> -->
                          </div>
                        </div>
                      </figcaption>
                    </figure>
                    <div class="effect-text-in-footer">
                      <div class="row">
                        <div class="col-md-12 text-center">
                          <h6 style="color:#696969 !important; text-align: center; margin-left:20px;">Sub Categories
                          </h6>
                        </div>
                        <!-- <div class="col-md-4"> -->
                          <!-- <toggle-button @change="changeStatus(item)"
                            :value="(item.status == 'Active') ? true : false" /> -->
                        <!-- </div> -->
                      </div>
                    </div>
                  </div>


                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                    <figure class="effect-text-in" style="height: 300px;">
                      <i class="mdi mdi-book-multiple" style="font-size: 150px; color: #ffc107;"></i>
                      <!-- <img src="" alt="image" /> -->
                      <!-- <h2 style="color:#696969 !important; text-align: left; margin-left:20px;">Courses</h2> -->
                      <figcaption>
                        <div>
                          <div class="btn-group" role="group" aria-label="Basic example"
                            style="position: absolute; bottom: 10px; left: 0; padding: 10px; margin-bottom: 0; width: 100%;">
                            <RouterLink class="btn btn-warning" to="/course/list"><i class="mdi mdi-eye"></i>
                            </RouterLink>
                            <!-- <button type="button"
                              @click="showEditModal(item.id, item.name, item.description, item.type, item.image)"
                              class="btn btn-warning"><i class="mdi mdi-lead-pencil"></i></button>
                            <button v-on:click="showAlert(item.id)" type="button" class="btn btn-danger"><i
                                class="mdi mdi-delete-forever"></i></button> -->
                          </div>
                        </div>
                      </figcaption>
                    </figure>
                    <div class="effect-text-in-footer">
                      <div class="row">
                        <div class="col-md-12 text-center">
                          <h6 style="color:#696969 !important; text-align: center; margin-left:20px;">Courses
                          </h6>
                        </div>
                        <!-- <div class="col-md-4"> -->
                          <!-- <toggle-button @change="changeStatus(item)"
                            :value="(item.status == 'Active') ? true : false" /> -->
                        <!-- </div> -->
                      </div>
                    </div>
                  </div>



                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                    <figure class="effect-text-in" style="height: 300px;">
                      <i class="mdi mdi-view-module" style="font-size: 150px; color: #dc3545;"></i>
                      <!-- <img src="" alt="image" /> -->
                      <!-- <h2 style="color:#696969 !important; text-align: left; margin-left:20px;">Modules</h2> -->
                      <figcaption>
                        <div>
                          <div class="btn-group" role="group" aria-label="Basic example"
                            style="position: absolute; bottom: 10px; left: 0; padding: 10px; margin-bottom: 0; width: 100%;">
                            <RouterLink class="btn btn-danger" to="/module/list"><i class="mdi mdi-eye"></i>
                            </RouterLink>
                            <!-- <button type="button"
                              @click="showEditModal(item.id, item.name, item.description, item.type, item.image)"
                              class="btn btn-warning"><i class="mdi mdi-lead-pencil"></i></button>
                            <button v-on:click="showAlert(item.id)" type="button" class="btn btn-danger"><i
                                class="mdi mdi-delete-forever"></i></button> -->
                          </div>
                        </div>
                      </figcaption>
                    </figure>
                    <div class="effect-text-in-footer">
                      <div class="row">
                        <div class="col-md-12 text-center">
                          <h6 style="color:#696969 !important; text-align: center; margin-left:20px;">Modules
                          </h6>
                        </div>
                        <!-- <div class="col-md-4"> -->
                          <!-- <toggle-button @change="changeStatus(item)"
                            :value="(item.status == 'Active') ? true : false" /> -->
                        <!-- </div> -->
                      </div>
                    </div>
                  </div>



                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                    <figure class="effect-text-in" style="height: 300px;">
                      <!-- <img src="" alt="image" /> -->
                      <i class="mdi mdi-content-copy" style="font-size: 150px; color: #17a2b8;"></i>
                      <!-- <h2 style="color:#696969 !important; text-align: left; margin-left:20px;">Content</h2> -->
                      <figcaption>
                        <div>
                          <div class="btn-group" role="group" aria-label="Basic example"
                            style="position: absolute; bottom: 10px; left: 0; padding: 10px; margin-bottom: 0; width: 100%;">
                            <RouterLink class="btn btn-info" to="/content/list"><i class="mdi mdi-eye"></i>
                            </RouterLink>
                            <!-- <button type="button"
                              @click="showEditModal(item.id, item.name, item.description, item.type, item.image)"
                              class="btn btn-warning"><i class="mdi mdi-lead-pencil"></i></button>
                            <button v-on:click="showAlert(item.id)" type="button" class="btn btn-danger"><i
                                class="mdi mdi-delete-forever"></i></button> -->
                          </div>
                        </div>
                      </figcaption>
                    </figure>
                    <div class="effect-text-in-footer">
                      <div class="row">
                        <div class="col-md-12 text-center">
                          <h6 style="color:#696969 !important; text-align: center; margin-left:20px;">Lessons
                          </h6>
                        </div>
                        <!-- <div class="col-md-4"> -->
                          <!-- <toggle-button @change="changeStatus(item)"
                            :value="(item.status == 'Active') ? true : false" /> -->
                        <!-- </div> -->
                      </div>
                    </div>
                  </div>



                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <vue-snotify></vue-snotify>
  </section>
</template>

<script>
import AudioCategoryEditModal from "../modals/audio-category-modals/AudioCategoryEditModal.vue";
import AudioCategoryAddModal from "../modals/audio-category-modals/AudioCategoryAddModal.vue";
import api from "../../config/api.js";

import config_course_categories from "@/data/course-categories";
import config_course from "@/data/course";
import config_module from "@/data/module";
import config_content from "@/data/content";

export default {
  name: "ListingContainer",
  components: {
    AudioCategoryEditModal,
    AudioCategoryAddModal,
  },

  props: {
    contentType: {
      required: true,
      type: Object
    }
  },

  data() {
    return {
      items_data: "",
    }
  },

  computed: {
    items: {
      get() {
        console.log('get');
        console.log(this.$store.getters[`${this.contentType.name}/list`]);
        return this.$store.getters[`${this.contentType.name}/list`];
      },
      set(value) {
        this.items_data = value;
      }
    }
  },
  async created() {
    this.$root.$refs.Category = this;
    await this.$store.dispatch(`${this.contentType.name}/all`);
    if (this.$store.getters['getMessage']) {
      let obj = this.$store.getters['getMessage'];
      this.$swal('Success!', obj.msg, 'success')
      // this.showSnotifySuccess(obj.msg)
      this.$store.dispatch('setMessage', "");
    }

    this.config_course_categories = config_course_categories.contentTypes;
    this.config_course = config_course.contentTypes;
    this.config_module = config_module.contentTypes;
    this.config_content = config_content.contentTypes;

  },
  methods: {

    async changeStatus(item) {
      item.status = !item.status
      try {
        let result = await api.post(`/admin/script-status?id=${item.id}`);
        this.$swal('Success!', result.data.message, 'success')
        await this.$store.dispatch(`script-categories/all`);
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },

    async showEditModal(id, name, description, type, image) {
      await this.$store.dispatch('setDataInEditForm', {
        "type": this.contentType.name,
        id, name, description, type, image
      })
      var obj = {
        "type": this.contentType.name,
        id, name, description, type, image
      };
      localStorage.setItem('data_for_edit', JSON.stringify(obj));
      // this.$refs['edit-modal'].show()
      const url = `/${this.contentType.name}/${id}/edit`
      // console.log(url);
      this.$router.push({ path: url });
    },

    async showAddModal() {
      await this.$store.dispatch('setDataInEditForm', { "type": this.contentType.name })
      // this.$refs['add-modal'].show()
      const url = `/${this.contentType.name}/add`
      console.log(url);
      this.$router.push({ path: url });
    },

    // showSnotifySuccess(msg) {
    //   let obj = this.$store.getters['getMessage'];
    //   this.$snotify.success(obj.msg, {
    //     timeout: 5000,
    //     showProgressBar: true,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //   });
    // },
    // showSnotifyError(msg) {
    //   let obj = this.$store.getters['getMessage'];
    //   this.$snotify.error(obj.msg, {
    //     timeout: 5000,
    //     showProgressBar: true,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //   });
    // },

    showAlert(id) {
      try {
        let endpoint2 = "/admin/delete-script/";
        const module_name = this.contentType.name;
        // if (module_name) {
        //   if (module_name == "hypnosis-categories") {
        //     endpoint2 = "/admin/delete-hypnosis-cat/";
        //   }
        //   else if (module_name == "meditation-categories") {
        //     endpoint2 = "/admin/delete-meditation-cat/";
        //   }
        //   else if (module_name == "affirmation-categories") {
        //     endpoint2 = "/admin/delete-affirmation-cat/";
        //   }
        // }
        this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then(async (res) => {
          if (res.value) {
            let result = await api.delete(endpoint2 + id);
            result = await this.$store.dispatch(`${this.contentType.name}/all`);
            this.$swal(
              'Deleted!',
              'Category has been deleted.',
              'success'
            )
          }
        })
      } catch (error) {
        let err = error.response.data.message;
        this.$store.dispatch('setMessage', { err });
        this.showSnotifyError()
      }
    }
  }
};
</script>

<style>
table {
  width: 100%;
  border-collapse: collapse;
}

thead th {
  text-align: left;
}

tbody tr {
  cursor: pointer;
  transition: background-color 0.2s;
}

tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

tbody tr:hover {
  background-color: #efefef;
}

td,
i th {
  padding: 0.25em;
}

.effect-text-in-footer {
  margin-bottom: 30px;
  padding-bottom: 10px;
  text-align: center;
  box-shadow: -2px 8px 20px 2px #0000002b;
}

.portfolio-grid .effect-text-in img {
  z-index: 100;
  opacity: 1;
  height: 50px;
  object-fit: cover;
}

.portfolio-grid figure {
  background: #fff !important;
}

.portfolio-grid .btn-outline-secondary:hover,
.portfolio-grid .btn-outline-secondary:focus,
.portfolio-grid .btn-outline-secondary:active {
  border-color: #3bccb0 !important;
  background: #3bccb0 !important;
  color: #ffffff;
}

.portfolio-grid .btn-outline-secondary,
.portfolio-grid .btn-outline-secondary,
.portfolio-grid .btn-outline-secondary {
  border-color: #3bccb0;
  color: #3bccb0;
}
</style>
